import React from "react";
import "../style/Banner.css";
import banner from "../assets/Banner.svg";
// import banner1 from "../assets/banner1.jpg"
// import banner2 from "../assets/banner2.jpg"
// import banner3 from "../assets/banner3.jpg"
// import banner4 from "../assets/banner4.jpg"
// import banner5 from "../assets/banner5.jpg"
// import banner6 from "../assets/banner6.jpg"
import vactor from "../assets/Vector.svg";
// import Slider from "react-slick";
import "../style/Client.css";

const Banner = ({ scrollToForm }) => {
  // var settings = {
  //     dots: false,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     autoplay: true,
  //     autoplaySpeed: 3000,
  //     arrows: false

  // };

  return (
    <div className="flex banner  z-10 bg-center bg-no-repeat  flex-col items-center mb-24 max-md:mb-10 pb-10 max-md:pb-20 px-10  max-md:px-5 h-full">
      <div className="flex flex-col mt-20 max-md:mt-5 max-md:max-w-full ">
        <div className="flex flex-col justify-center items-center max-w-[1280px] overflow-hidden">
          <div className="flex-wrap  justify-between content-center max-md:max-w-full ">
            <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5 max-md:gap-0">
              <div className="flex flex-col max-md:ml-0 max-md:w-full">
                <div className="flex flex-col justify-center max-md:items-center self-stretch my-auto max-md:mt-10 max-md:max-w-full">
                  <div className="text-6xl/[4.5rem] font-bold text-white max-md:max-w-full max-md-px-5 max-md:text-[1.5rem]/[2.5rem]  max-md:text-center">
                    Welcome to Eazotel
                    {/* by Fielmente Hospitality Marketing Agency */}
                  </div>
                  <div className="text-3xl max-md:text-[24px] mt-5 text-white whitespace-wrap overflow-hidden  max-md:w-full max-md:text-center max-md:mt-4">
                    Your Complete Hospitality Website Management Solution
                  </div>
                  <div className=" max-md:hidden flex gap-5 justify-between items-center pr-20 mt-8 max-md:mt-4 max-md:flex-wrap max-md:pr-0 max-md:max-w-full max-md:justify-center max-md:gap-2">
                    <img
                      alt="icon"
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                      className="self-stretch my-auto max-w-full aspect-[4.17] w-[117.6px]"
                    />
                    <img
                      alt="icon"
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                      className="self-stretch mt-1 max-w-full aspect-[4.55] w-[117.6px]"
                    />
                    <img
                      alt="icon"
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                      className="self-stretch max-w-full aspect-[4.35] w-[117.6px]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-end items-end max-md:justify-between max-md:items-center max-md:ml-0 max-md:w-full">
                <div className="">
                  <img
                    alt="icon"
                    loading="lazy"
                    src={banner}
                    className="grow max-md:mt-8  max-md:max-w-full h-[100%]"
                  />
                </div>

                <div className="lg:hidden block flex justify-between mt-10 w-full">
                  <img
                    alt="icon"
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9517f8a1bab4fe22bf7a88939d5c1505c4f134837987628aaf37c29957e1b7db?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                    className="self-stretch my-auto max-w-full aspect-[4.17] w-[85.61px] max-md:w-[100px]"
                  />
                  <img
                    alt="icon"
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb440c732cade7b6daa6d93ceeeb770e57b468aa67be7e56c4f4cee0909e9c65?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                    className="self-stretch mt-1 max-w-full aspect-[4.55] w-[77px] max-md:w-[100px]"
                  />
                  <img
                    alt="icon"
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e3912b08f4898d8754dd1a2c7418f42033ce58460d3434888f0f561f47b993a1?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                    className="self-stretch max-w-full aspect-[4.35] w-[100px] max-md:w-[110px]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={scrollToForm}
            className=" cursor-pointer max-md:hidden flex self-center mt-20 text-base font-semibold text-white w-[84px] max-md:mt-10"
          >
            <div className="grow my-auto">Scroll</div>
            <img
              alt="icon"
              loading="lazy"
              src={vactor}
              className="flex-1 shrink-0 w-full aspect-square"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
